import '../../App.css'
import { Modal } from "antd";


function CasinoResultModal(props) {
    console.log('props', props);

    return (
        <>
            <Modal className='recent-result-modal relative top-1 lg:top-7 w-[500px]' open={props.isModalOpen} footer={null}>
                <div className='grid grid-cols-12'>
                    <div className='col-span-12 flex rounded-t-[8px] items-center h-[45px] justify-between p-[10px] bg-[linear-gradient(-180deg,_#315195_0%,_#14213D_100%)] border-[1px] border-[solid] border-[#ffffff] text-[#ffffff]'>
                        <span className='leading-normal text-[.938rem] font-black'>
                            {
                                props.event_type == "1004" ?
                                    '20-20 TEEN PATTI'
                                    : props.event_type == "1008" ?
                                        'LUCKY7 - A'
                                        : props.event_type == "1005" ?
                                            '20-20 DRAGON TIGER'
                                            : props.event_type == "1002" ?
                                                'ANDAR BAHAR - A'
                                                : props.event_type == "1007" ?
                                                    '32 CARDS - A'
                                                    : props.event_type == "1009" ?
                                                        '1DAY TEEN PATTI'
                                                        : props.event_type == "1010" ?
                                                            'BACCARAT'
                                                            : props.event_type == "1011" ?
                                                                '20-20 POKER - A '
                                                                : props.event_type == "1012" ?
                                                                    'DRAGON TIGER'
                                                                    : props.event_type == "1013" ?
                                                                        '1 DAY DRAGON TIGER'
                                                                        : ''
                            } </span>
                        <button type="button" className='text-[1.3rem] font-black' onClick={props.handleCancel}>×</button>
                    </div>
                    <div className='col-span-12 max-h-[80vh] overflow-y-auto '>
                        <div className='grid grid-cols-12 mb-[80px]'>
                            <div className='col-span-12 p-[.3rem]'>
                                <h6 className='font-black text-[.8rem] text-end'>Round Id: {props?.resultRound?.roundId} </h6>
                            </div>


                            {
                                props.event_type == "1004" || props.event_type == "1009" ?

                                    <div className='col-span-12 p-[.3rem]'>
                                        <div className='text-center border-r-[#ddd] py-[3px] my-[5px]'>
                                            <h6 className='text-[1.2rem] font-black' >Player A</h6>
                                            <div className=''>
                                                <div className='flex justify-center'>
                                                    <img className='w-[50px] border-[1px] border-black rounded-[17px] m-[6px]'
                                                        src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                            ? props?.cardRound?.data?.cardsArr?.PLAYER_A?.card_1
                                                            : "0"
                                                            }.png`}
                                                    ></img>
                                                    <img className='w-[50px] border-[1px] border-black rounded-[17px] m-[6px]' src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                        ? props?.cardRound?.data?.cardsArr?.PLAYER_A?.card_2
                                                        : "0"
                                                        }.png`}></img>
                                                    <img className='w-[50px] border-[1px] border-black rounded-[17px] m-[6px]' src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                        ? props?.cardRound?.data?.cardsArr?.PLAYER_A?.card_3
                                                        : "0"
                                                        }.png`}></img>
                                                </div>
                                                {
                                                    props?.winner == "A" &&
                                                    <button className='bg-[#28a745] py-[5px] px-[10px] text-[#fff] text-[20px] font-black block my-[10px] mx-auto rounded-[.375rem]'>Winner</button>
                                                }
                                            </div>
                                        </div>
                                        <div className='text-center border-t-[1px] border-r-[#ddd] py-[3px] my-[5px]'>
                                            <h6 className='text-[1.2rem] font-black' >Player B</h6>
                                            <div className=''>
                                                <div className='flex justify-center'>
                                                    <img className='w-[50px] border-[1px] border-black rounded-[17px] m-[6px]' src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                        ? props?.cardRound?.data?.cardsArr?.PLAYER_B?.card_1
                                                        : "0"
                                                        }.png`}></img>
                                                    <img className='w-[50px] border-[1px] border-black rounded-[17px] m-[6px]' src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                        ? props?.cardRound?.data?.cardsArr?.PLAYER_B?.card_2
                                                        : "0"
                                                        }.png`}></img>
                                                    <img className='w-[50px] border-[1px] border-black rounded-[17px] m-[6px]' src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                        ? props?.cardRound?.data?.cardsArr?.PLAYER_B?.card_3
                                                        : "0"
                                                        }.png`}></img>
                                                </div>
                                                {
                                                    props?.winner == "B" &&
                                                    <button className='bg-[#28a745] py-[5px] px-[10px] text-[#fff] text-[20px] font-black block my-[10px] mx-auto rounded-[.375rem]'>Winner</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    : props.event_type == "1008" ?
                                        <div className='col-span-12 p-[.3rem]'>
                                            <div className='text-center border-t-[1px] border-r-[#ddd] py-[3px] my-[5px]'>
                                                <div className=''>
                                                    <div className='flex justify-center'>
                                                        <img className='w-[50px] border-[1px] border-black rounded-[17px] m-[6px]' src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                            ? props?.cardRound?.data?.cardsArr?.card
                                                            : "0"
                                                            }.png`}></img>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : props.event_type == "1005" || props.event_type == "1012" ?
                                            <>
                                                <div className='col-span-6 text-center border-r-[#ddd] py-[3px] my-[5px]'>
                                                    <h6 className='text-[1.2rem] font-black' >Dragon</h6>
                                                    <div className=''>
                                                        <div className='flex justify-center'>
                                                            <img className='w-[50px] border-[1px] border-black rounded-[17px] m-[6px]' src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                ? props?.cardRound?.data?.cardsArr?.DRAGON.card_1
                                                                : "0"
                                                                }.png`}></img>
                                                        </div>
                                                        {
                                                            props?.winner == "D" &&
                                                            <button className='bg-[#28a745] py-[5px] px-[10px] text-[#fff] text-[20px] font-black block my-[10px] mx-auto rounded-[.375rem]'>Winner</button>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-span-6 text-center border-l-[1px] border-r-[#ddd] py-[3px] my-[5px]'>
                                                    <h6 className='text-[1.2rem] font-black' >Tiger</h6>
                                                    <div className=''>
                                                        <div className='flex justify-center'>
                                                            <img className='w-[50px] border-[1px] border-black rounded-[17px] m-[6px]' src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                ? props?.cardRound?.data?.cardsArr?.TIGER.card_1
                                                                : "0"
                                                                }.png`}></img>
                                                        </div>
                                                        {
                                                            props?.winner == "T" &&
                                                            <button className='bg-[#28a745] py-[5px] px-[10px] text-[#fff] text-[20px] font-black block my-[10px] mx-auto rounded-[.375rem]'>Winner</button>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                            : props.event_type == "1007" ?
                                                <>
                                                    <div className='col-span-6 text-center py-[3px] my-[5px]'>
                                                        <ul className="showall-card w-full [list-style:none] flex flex-wrap items-center px-[5px] py-[2px] mb-[3px]">
                                                            <li className="mr-2">Player 8 <span>-</span></li>
                                                            <li><img className="imgClassModal me-1 w-[30px] border-[1px] border-black rounded-[5px] m-[6px]" src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                ? props?.cardRound?.data?.cardsArr?.PLAYER_8[0] : "0"
                                                                }.png`} /></li>
                                                            {
                                                                props.winner == '8' ?
                                                                    <li><span><i class="fa fa-trophy winner-icon"></i></span></li>
                                                                    :
                                                                    <li><span><i className="fa fa-trophy winner-icon loser-icon"></i></span></li>
                                                            }
                                                        </ul>
                                                        <ul className="showall-card w-full [list-style:none] flex flex-wrap items-center px-[5px] py-[2px] mb-[3px]">
                                                            <li className="mr-2">Player 9 <span>-</span></li>
                                                            <li><img className="imgClassModal me-1 w-[30px] border-[1px] border-black rounded-[5px] m-[6px]" src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                ? props?.cardRound?.data?.cardsArr?.PLAYER_9[0] : "0"
                                                                }.png`} /></li>
                                                            {
                                                                props.winner == '9' ?
                                                                    <li><span><i class="fa fa-trophy winner-icon"></i></span></li>
                                                                    :
                                                                    <li><span><i className="fa fa-trophy winner-icon loser-icon"></i></span></li>
                                                            }
                                                        </ul>
                                                        <ul className="showall-card w-full [list-style:none] flex flex-wrap items-center px-[5px] py-[2px] mb-[3px]">
                                                            <li className="mr-2">Player 10 <span>-</span></li>
                                                            <li><img className="imgClassModal me-1 w-[30px] border-[1px] border-black rounded-[5px] m-[6px]" src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                ? props?.cardRound?.data?.cardsArr?.PLAYER_10[0] : "0"
                                                                }.png`} /></li>
                                                            {
                                                                props.winner == '10' ?
                                                                    <li><span><i class="fa fa-trophy winner-icon"></i></span></li>
                                                                    :
                                                                    <li><span><i className="fa fa-trophy winner-icon loser-icon"></i></span></li>
                                                            }
                                                        </ul>
                                                        <ul className="showall-card w-full [list-style:none] flex flex-wrap items-center px-[5px] py-[2px] mb-[3px]">
                                                            <li className="mr-2">Player 11 <span>-</span></li>
                                                            <li><img className="imgClassModal me-1 w-[30px] border-[1px] border-black rounded-[5px] m-[6px]" src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                ? props?.cardRound?.data?.cardsArr?.PLAYER_11[0] : "0"
                                                                }.png`} /></li>
                                                            {
                                                                props.winner == '11' ?
                                                                    <li><span><i class="fa fa-trophy winner-icon"></i></span></li>
                                                                    :
                                                                    <li><span><i className="fa fa-trophy winner-icon loser-icon"></i></span></li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </>
                                                : props.event_type == "1010" ?
                                                    <>
                                                        <div className='col-span-12 p-[.3rem]'>
                                                            <div className='text-center border-r-[#ddd] py-[3px] my-[5px]'>
                                                                <h6 className='text-[1.2rem] font-black' >Player</h6>
                                                                <div className=''>
                                                                    <div className='flex justify-center'>
                                                                        <img className='w-[50px] border-[1px] border-black rounded-[17px] m-[6px]' src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.PLAYER.card_1
                                                                            : "0"
                                                                            }.png`}></img>
                                                                        <img className='w-[50px] border-[1px] border-black rounded-[17px] m-[6px]' src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.PLAYER.card_2
                                                                            : "0"
                                                                            }.png`}></img>
                                                                        <img className='w-[50px] h-auto ml-[20px] rotate-90 border-[1px] border-black rounded-[17px]' src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.PLAYER.card_3
                                                                            : "0"
                                                                            }.png`}></img>
                                                                    </div>
                                                                    {
                                                                        props?.winner == "P" &&
                                                                        <button className='bg-[#28a745] py-[5px] px-[10px] text-[#fff] text-[20px] font-black block my-[10px] mx-auto rounded-[.375rem]'>Winner</button>
                                                                    }                                                                </div>
                                                            </div>
                                                            <div className='text-center border-t-[1px] border-r-[#ddd] py-[3px] my-[5px]'>
                                                                <h6 className='text-[1.2rem] font-black' >Banker</h6>
                                                                <div className=''>
                                                                    <div className='flex justify-center'>
                                                                        <img className='w-[50px] border-[1px] border-black rounded-[17px] m-[6px]' src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.BANKER.card_1
                                                                            : "0"
                                                                            }.png`}></img>
                                                                        <img className='w-[50px] border-[1px] border-black rounded-[17px] m-[6px]' src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.BANKER.card_2
                                                                            : "0"
                                                                            }.png`}></img>
                                                                        <img className='w-[50px] h-auto ml-[20px] rotate-90 border-[1px] border-black rounded-[17px]' src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.BANKER.card_3
                                                                            : "0"
                                                                            }.png`}></img>
                                                                    </div>
                                                                    {
                                                                        props?.winner == "B" &&
                                                                        <button className='bg-[#28a745] py-[5px] px-[10px] text-[#fff] text-[20px] font-black block my-[10px] mx-auto rounded-[.375rem]'>Winner</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : props.event_type == "1011" ?
                                                        <>
                                                            <div className='col-span-12 text-center py-[3px] my-[5px]'>
                                                                <ul className="showall-card w-full [list-style:none] flex flex-wrap items-center px-[5px] py-[2px] mb-[3px]">
                                                                    <li className="mr-2 font-bold">BOARD <span>-</span></li>
                                                                    <li><img className="imgClassModal me-1 w-[30px] border-[1px] border-black rounded-[5px] m-[6px]" src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.BOARD.card_1
                                                                            : "0"
                                                                            }.png`} /></li>
                                                                    <li><img className="imgClassModal me-1 w-[30px] border-[1px] border-black rounded-[5px] m-[6px]" src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.BOARD.card_2
                                                                            : "0"
                                                                            }.png`} /></li>
                                                                    <li><img className="imgClassModal me-1 w-[30px] border-[1px] border-black rounded-[5px] m-[6px]" src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.BOARD.card_3
                                                                            : "0"
                                                                            }.png`} /></li>
                                                                    <li><img className="imgClassModal me-1 w-[30px] border-[1px] border-black rounded-[5px] m-[6px]" src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.BOARD.card_4
                                                                            : "0"
                                                                            }.png`} /></li>
                                                                    <li><img className="imgClassModal me-1 w-[30px] border-[1px] border-black rounded-[5px] m-[6px]" src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.BOARD.card_5
                                                                            : "0"
                                                                            }.png`} /></li>

                                                                </ul>
                                                                <ul className="showall-card w-full [list-style:none] flex flex-wrap items-center px-[5px] py-[2px] mb-[3px]">
                                                                    <li className="mr-2 font-bold">PLAYER A <span>-</span></li>
                                                                    <li><img className="imgClassModal me-1 w-[30px] border-[1px] border-black rounded-[5px] m-[6px]" src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.PLAYER_A.card_1
                                                                            : "0"
                                                                            }.png`} /></li>
                                                                    <li><img className="imgClassModal me-1 w-[30px] border-[1px] border-black rounded-[5px] m-[6px]" src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.PLAYER_A.card_2
                                                                            : "0"
                                                                            }.png`} /></li>

                                                                </ul>
                                                                <ul className="showall-card w-full [list-style:none] flex flex-wrap items-center px-[5px] py-[2px] mb-[3px]">
                                                                    <li className="mr-2 font-bold">PLAYER B <span>-</span></li>
                                                                    <li><img className="imgClassModal me-1 w-[30px] border-[1px] border-black rounded-[5px] m-[6px]" src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.PLAYER_B.card_1
                                                                            : "0"
                                                                            }.png`} /></li>
                                                                    <li><img className="imgClassModal me-1 w-[30px] border-[1px] border-black rounded-[5px] m-[6px]" src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.PLAYER_B.card_2
                                                                            : "0"
                                                                            }.png`} /></li>

                                                                </ul>

                                                            </div>
                                                        </>
                                                        : props.event_type == "1013" ?
                                                            <>
                                                                <div className='col-span-12 text-center py-[3px] my-[5px]'>

                                                                    <ul className="showall-card w-full [list-style:none] flex flex-wrap items-center px-[5px] py-[2px] mb-[3px]">
                                                                        <li className="mr-2 font-bold">DRAGON<span>-</span></li>
                                                                        <li><img className="imgClassModal me-1 w-[30px] border-[1px] border-black rounded-[5px] m-[6px]" src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.DRAGON.card_1
                                                                            : "0"
                                                                            }.png`} /></li>
                                                                    </ul>
                                                                    <ul className="showall-card w-full [list-style:none] flex flex-wrap items-center px-[5px] py-[2px] mb-[3px]">
                                                                        <li className="mr-2 font-bold">TIGER<span>-</span></li>
                                                                        <li><img className="imgClassModal me-1 w-[30px] border-[1px] border-black rounded-[5px] m-[6px]" src={`https://casino.kingexch9.com/assets/cards/${props?.cardRound
                                                                            ? props?.cardRound?.data?.cardsArr?.TIGER.card_1
                                                                            : "0"
                                                                            }.png`} /></li>
                                                                    </ul>

                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                            }

                            {
                                props?.resultRound?.data?.resultsArr?.map(result => {
                                    return (
                                        <>
                                            <div className='col-span-12 border-y-[1px] border-[#ccc] py-[10px]'>
                                                <h6 className='text-[.8rem] font-black text-center'>{result?.marketName}</h6>

                                                {Object.keys(result.runners).map((runnerId) => (
                                                    <>
                                                        <div className="w-[50%] inline-block text-center text-[.75rem] p-[.25rem]">
                                                            {result?.runnersName[runnerId]}

                                                            {
                                                                result?.runners[runnerId] == "WINNER" ?
                                                                    <i className="fa fa-trophy winner-icon"></i>
                                                                    :
                                                                    <i className="fa fa-trophy winner-icon loser-icon"></i>
                                                            }

                                                        </div>
                                                    </>
                                                )
                                                )
                                                }

                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </>

    )

}

export default CasinoResultModal;
